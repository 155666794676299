.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  display: block;
  margin: 0 auto;
}

.container-sidebar{
  background-color: #282c34;
  position: fixed;
  height: 100%;
}

.App-sidebar .title{
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  margin: 10px;
}

.bg-purple{
  background-color: #CC4E98;
}

header{
  border-bottom: 1px solid #282c34;
}

header p{
  margin: 20px;
}

.item-sidebar{
  padding-top: 20px;
  font-size: 16px;
}

.item-sidebar a{
  color: white;
}

.item-sidebar a:hover{
  color: #CC4E98;
  text-decoration: none;
  font-size: 17px;
  margin-left: 10px;
  transition: 1s;
}

.text-item{
  padding-left: 10px;
}

.title-page{
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 20px;
  border-bottom: 2px solid #CC4E98;
  color: #282c34;
}

.btn-default{
  background-color: #CC4E98;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

a{
  color: #CC4E98;
}

.btn-default a, .btn-default{
  color: white;
}

label{
  font-weight: bold;
}

.btn-form{
  border: 1px solid #cecece;
}

.btn-form:hover{
  background-color: rgba(240,240,240);
}

.form-login{
  margin-top: 20px;
}

.login-box{
  border-radius: 15px;
  border: 2px solid #CECECE;
  margin-top: 50px;
}

.logo-login{
  margin: 20px auto;
  display: block;
  max-width: 90%;
}

.btn-close-modal{
  position: absolute;
  top: 20px;
  right: 20px;
}

.print-title{
  font-weight: bold;
  margin: 15px;
}

.text-underscore{
  text-decoration: underline;
}

.margin-top{
  margin-top: 30px;
}

.hide{
  display: none;
}

.btn-print{
  border: #CC4E98 1px solid;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  width: 150px;
  text-align: center;
  margin: 30px;
}

.qtdeAlertas{
  background-color: red;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  margin-left: 15px;
}

@media print {
  .btn-print{
    display: none;
  }
}